import React from 'react';

import './App.scss';

const App: React.FC = () => {
  return (
    <div className='App'>
      <div className='top'>
        <div className='card'>
          <h2>鸦片战争</h2>
          <p>
            1840年，英国发动了侵略中国的鸦片战争。战争中，广大爱国官兵和三元里人民进行了英勇战斗。但由于清政府奉行妥协方针，终于导致战争的失败。1842年，英国强迫清政府签订《中英南京条约》，中国的独立和领土完整开始遭到破坏，从封建社会开始沦为半殖民地半封建社会。中国社会的半殖民地化程度，进一步加深。
          </p>
        </div>
        <div className='card'>
          <h2>太平天国运动</h2>
          <p>
            清朝咸丰元年到同治三年（1851年—1864年）期间，由洪秀全、杨秀清、萧朝贵、冯云山、韦昌辉、石达开等组成的领导集团从广西金田村率先发起的反对清朝封建统治和外国资本主义侵略的农民起义战争，是19世纪中叶中国最大的一场大规模反清运动。1864年，随着太平天囯首都天京的（南京）陷落，标志着运动失败。
          </p>
        </div>
        <div className='card'>
          <h2>清朝后期资本主义的产生和民族危机的加深</h2>
          <p>
            19世纪60年代，清朝统治阶级内部出现了洋务派，掀起了洋务运动，刺激了中国资本主义的发展，促进了中国近代化的历程。19世纪末，世界资本主义向帝国主义过渡，帝国主义国家加紧了对中国的侵略。之后，先后爆发中法战争和中日甲午战争。《中法新约》与《马关条约》的签订大大加深了中国社会的半殖民地化。
          </p>
        </div>
        <div className='card'>
          <h2>戊戌变法和义和团运动</h2>
          <p>
            甲午中日战争后，民族资产阶级登上历史舞台。资产阶级维新派掀起维新变法运动，起到了思想启蒙的作用，它的失败说明资产阶级改良道路在中国行不通。义和团运动粉碎了帝国主义列强瓜分中国的狂妄计划，打击了清政府的反动统治。1900年，八国联军侵略中国。1901年，清政府被迫同11国签订《辛丑条约》，标志着中国半殖民地半封建社会的形成。
          </p>
        </div>
        <div className='card'>
          <h2>辛亥革命和清朝的灭亡</h2>
          <p>
            辛亥革命是中国近代史上一次反帝反封建的资产阶级民主革命，推翻存在中国两千多年的君主制度，建立资产阶级民主共和国，颁布反映资产阶级民主主义精神的临时约法，打击了帝国主义的殖民统治，为中国民族资本发展创造条件。辛亥革命没有改变中国半殖民地半封建社会的性质，证明在帝国主义时代，半殖民地半封建的中国不可能走西方的老路。
          </p>
        </div>
        <div className='card'>
          <h2>中华民国初期北洋军阀的统治</h2>
          <p>
            1912年3月，袁世凯篡夺革命果实，就任中华民国临时大总统，北洋军阀政权建立。袁世凯镇压国民党，出卖国家主权，孙中山号召武力讨袁。孙中山再次组织武力讨袁，护国运动爆发，袁世凯被迫取消帝制。袁世凯死后，中国军阀割据。孙中山倡导了护法运动。一战期间帝国主义放松对中国的经济侵略，中国民族工业得到短暂的发展。
          </p>
        </div>
      </div>
      <div className='center'>
        <div className='left'>
          <span>中国</span>
          <span>近现代历史馆</span>
        </div>
        <div className='right'>
          <h3>后之视今，犹今之视昔。</h3>
          <span>——班固《汉书·京房传》</span>
          <a href='/home.html'>
            <div className='btn'>进入</div>
          </a>
        </div>
      </div>
      <div className='bottom'>
        <div className='card'>
          <h2>五四运动</h2>
          <p>
            一战期间，新文化运动发起。1915年，陈独秀创办《新青年》，成为新文化运动兴起的标志，掀起了思想解放的潮流。1919年，五四运动爆发，是中国新民主主义革命的开端。之后，马克思主义成为中国新思潮的主流。从香港海员罢工到京汉铁路工人罢工，中国工人运动出现了第一次高潮。
          </p>
        </div>
        <div className='card'>
          <h2>中国共产党的创立</h2>
          <p>
            中国共产党的最早组织是在上海首先建立的。1920年8月，上海共产党早期组织正式成立。参加者有陈独秀、李汉俊、李达、陈望道、俞秀松等，陈独秀任书记。上海共产党早期组织成立后，实际上成为各地建党活动的联络中心，起着中国共产党发起组的重要作用。
          </p>
        </div>
        <div className='card'>
          <h2>第一次国内革命战争</h2>
          <p>
            第一次国内革命战争是中国人民在中国共产党领导下进行的反对帝国主义、北洋军阀的战争。中国共产党同孙中山领导的国民党合作，建立革命统一战线。国民党建立黄埔军校，奠定革命军基础。全国反帝反封建国民大革命运动迅速开展。各地工人掀起反帝爱国运动的高潮。国民政府开始北伐。孙中山逝世后，国民党右派加紧争夺革命领导权。国民革命失败了。
          </p>
        </div>
        <div className='card'>
          <h2>第二次国内革命战争</h2>
          <p>
            第二次国内革命战争是中国人民在中国共产党领导下反对国民党反动统治的战争。1927年召开“八七”会议，发动了南昌起义，创建红军开辟农村根据地并进行土地革命，开辟了农村包围城市武装夺取政权的道路。1931年，日本发动“九·一八”事变。国民党的不抵抗政策使得东北三省沦亡。1933年秋，蒋介石发动第五次“围剿”。红军取得了长征的胜利。
          </p>
        </div>
        <div className='card'>
          <h2>抗日战争</h2>
          <p>
            1937年7月7日，日军进攻卢沟桥，全国抗日战争序幕揭开。八路军、新四军开展游击战争，建立了许多抗日根据地，取得了很大胜利。中国共产党在政治上、经济上、思想上采取了一系列措施，终于度过了最困难时期。1944年，解放区军民开始局部反攻。8月15日，日本政府宣布无条件投降。经过八年艰苦奋战，中国人民取得抗日战争的伟大胜利。
          </p>
        </div>
        <div className='card'>
          <h2>第三次国内革命战争</h2>
          <p>
            第三次国内革命战争是中国人民在中国共产党领导下反对国民党反动派的战争。国民党反动派发动内战。1945年8月，毛泽东赴重庆同国民党进行谈判，国共双方代表签订了《双十协定》。1946年夏，国民党军队向解放区发动进攻，全面内战爆发。1949年9月，第一届中国人民政治协商会议召开，标志着中国人民民主革命的伟大胜利。
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;
