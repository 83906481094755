import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './index.scss';

const { StrictMode: Strict } = React;
const root = document.getElementById('root');

ReactDOM.render(
  <Strict>
    <App />
  </Strict>,
  root
);
